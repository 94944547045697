@import 'variables';
@import './mixins/overflow-ellipsis';

.file-selector {
  input {
    display: none;
  }

  .drop-box {
    background-color: @color-control-default-background;
    border: 1px dashed @color-control-border;
    border-radius: 5px;
    text-align: center;

    .x-small {
      font-size: 65%;
      font-weight: normal;
    }

    a {
      color: @color-control-default-cancel;

      &:hover {
        color: @color-control-active-cancel;
      }
    }

    &.dragover {
      background-color: @color-control-valid-background;
    }
  }

  .drop-box-small {
    font-size: @small-responsive-font-size;

    .file-selector-spacer {
      margin: @space-light;
    }
  }

  .drop-box-medium {
    font-size: @medium-responsive-font-size;

    .file-selector-spacer {
      margin: @space-medium;
    }
  }

  .drop-box-large {
    font-size: @large-responsive-font-size;

    .file-selector-spacer {
      margin: @space-heavy;
    }
  }

  .selected-file-name {
    .overflow-ellipsis();
  }

}

[disabled] .file-selector .well {
  cursor: not-allowed;
  opacity: 0.50;
}
