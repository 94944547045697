@import 'variables';

.workflows {
  .auto-refresh-message {
    font-size: @small-font-size;
  }

  .inputs-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;

    .container-label {
      padding-top: 7px;
    }

    .container-input {
      padding: 0 25px 0 10px;
    }
  }

  .refresh-icon {
    color: @color-panel-heading-icon;

    &:hover {
      color: @color-panel-heading-icon;
    }

    > i {
      position: relative;
      top: 5px;
    }
  }

  .update-filter-action {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .viewer-header-widget:last-child {
    border-right: 0 !important; // lesshint importantRule: false
  }

  th.expand {
    width: 50px;
  }

  th.namespace {
    width: 220px;
  }

  th.scope {
    width: 150px;
  }

  th.status {
    width: 230px;
  }

  th.trigger {
    width: 125px;
  }

  th.variant {
    width: 170px;
  }
}
