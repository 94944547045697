@import 'variables';

.confirmation-popover {
  .popover-title {
    font-weight: bold;
  }

  .popover-content .content {
    color: @color-text-danger;
  }

  .actions {
    padding-top: @space-light;
    text-align: center;
  }
}
