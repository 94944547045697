@import 'variables';

.clock {
  .date-time {
    color: @subtle-font-color;
    font-family: @monospace-font-family;
    font-size: @small-font-size;
  }

  .time-zone {
    color: @subtle-font-color;
    font-size: @xsmall-font-size;
  }
}
