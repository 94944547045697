.shortcuts {
  legend {
    font-size: @default-font-size !important; // lesshint-disable-line importantRule
    font-weight: bold !important; // lesshint-disable-line importantRule
    margin-bottom: 15px;
    padding: 0 10px;

    > span {
      background: @color-page-background;
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      top: 10px;
    }
  }

  .panel {
    [sv-element] {
      background-color: #f5f5f5;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      cursor: grab;
      margin-bottom: 20px;
      min-height: 20px;
      padding: 9px;
    }

    &.panel-success {
      [sv-element] {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
      }
    }

    // sv-helper is used for the element currently being dragged
    .sv-helper {
      cursor: grabbing;
    }
  }

  .your-shortcuts-action-button {
    margin-right: 5px;
    margin-top: -8px;
  }

  .reorder-shortcuts-action-button {
    margin-right: 5px;
    margin-top: -5px;
  }

  .create-shortcut-preview {
    margin-bottom: 0;
    padding: 10px;
  }

  .create-shortcut-step {
    .panel-heading {
      padding: 0 10px;
    }

    .panel-body {
      padding: 5px;
    }
  }

  .edit-shortcut-details {
    .panel-heading {
      padding: 5px 5px;
    }

    .panel-body {
      padding: 5px;
    }
  }

  .listed-shortcut {
    padding: 10px;
  }

  .rank-delta-separator {
    margin-left: 10px;
    margin-right: 10px;
  }

  .selected-shortcut {
    border: 1px solid #3c763d;
  }

  .shortcuts-count {
    margin-left: 5px;
  }

  .scope-tenant-selections-container {
    align-items: flex-start;
    display: flex !important; // lesshint importantRule: false
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .scope-selection-container {
    flex-grow: 1;
    padding-left: 5px;
  }

  .tenant-selection-container {
    width: 110px;
  }
}
