/**
 * Defines selector button styles.
 */
@import './overflow-ellipsis';

.selector-button() {
  > div {
    padding-right: 20px;

    .overflow-ellipsis();
  }

  .fa-caret-down {
    line-height: inherit;
    position: absolute;
    right: 12px;
    top: 7px;
  }
}
