@import 'variables';

.overlay-container {
  align-items: center;
  background-color: fade(@color-page-background, 50%);
  display: flex;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
