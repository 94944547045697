@import 'variables';
@import 'mixins/hard-dimensions';

// These styles are explicitly used for the Application loading container that may be visible prior to Angular loading.
.application-loading-container {
  display: table;
  text-align: center;

  .hard-height(100%);
  .hard-width(100%);
}

.application-loading-block {
  display: table-cell;
  vertical-align: middle;

  .hard-height(100%);
}
