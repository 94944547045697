@import 'variables';

.btn.btn-tile {
  display: block;
  padding: 23% 12px;
  width: 100%;

  .title {
    color: @default-font-color;
    font-family: @header-font-family;
    font-size: @large-font-size;
  }

  .sub-title {
    color: @subtle-font-color;
    font-family: @header-font-family;
  }
}
