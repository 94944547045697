/**
 * Defines dropdown list styles.
 */

@import '../variables';

.dropdown-list() {
  ul {
    > li {
      border-bottom: 1px solid @color-control-border;
    }

    > li:last-child {
      border: 0;
    }
  }

  .menu-item {
    .line-one {
      font-size: @small-font-size;
      font-weight: bold;
    }

    .line-two {
      font-size: @xsmall-font-size;
    }
  }
}
