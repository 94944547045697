/*
 * Border definitions.
 */
@border-radius-default: 4px;
@border-width-default: 1px;

/*
 * Color definitions.
 */
@color-control-default-background: #fff;
@color-control-default-cancel: #b7b7b7;
@color-control-active-cancel: #f2a9a9;
@color-control-invalid-background: #f2dede;
@color-control-valid-background: #edfced;
@color-control-border: #ccc;
@color-control-soft-border: #dfdfdf;

@color-grid-group-highlight: #e0ffff;
@color-grid-non-editable: #a9a9a9;
@color-grid-non-editable-background: #f5f5f5;

@color-page-background: #fff;
@color-page-trim-background: #f8f8f8;
@color-page-trim-border: #e7e7e7;

@color-panel-heading-icon: #333;

@color-sidebar-background: #fff;

@color-env-local: #3333ff;
@color-env-beta: #009933;
@color-env-gamma: #cc0000;
@color-env-prod: #f97b00;

@color-preferences-background: #5bc0de;

@color-text-danger: #b94a48;
@color-text-info: #3a87ad;
@color-text-success: #468847;
@color-text-warning: #c09853;

@color-today-background: #fff6f6;

@dataset-actuals-background-color: #edfced;
@dataset-actuals-yoy-py-background-color: #f2e9fb;
@dataset-actuals-yoy-ppy-background-color: #e6d4f7;
@dataset-actual-comparison-first-background-color: #e6f3ff;
@dataset-actual-comparison-second-background-color: #cce6ff;
@dataset-comparison-first-background-color: #f9ebd2;
@dataset-comparison-second-background-color: #f4dcaf;
@dataset-cumulative-background-color: #f3d6a5;
@dataset-cumulative-end-of-week-background-color: #c9c9c9;
@dataset-default-background-color: #fafafa;
@dataset-delta-background-color: #fff;
@dataset-edits-background-color: #33ccff;
@dataset-percent-background-color: #fff;
@dataset-primary-background-color: #ffffed;
@header-aggregate-background-color: #f9f9f9;

/*
 * Font definitions.
 */
@default-font-color: #000;
@contrast-font-color: #fff;
@subtle-font-color: #a9a9a9;
@alarm-font-color: #ff0000;

@default-font-family: 'Open Sans', sans-serif;
@header-font-family: 'Droid Sans', sans-serif;
@data-grid-font-family: 'Open Sans', sans-serif;
@monospace-font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

@footer-font-size: .75em;
@large-responsive-font-size: 1.5vw;
@medium-responsive-font-size: 1.25vw;
@small-responsive-font-size: 1.0vw;
@jumbo-font-size: 21px;
@large-font-size: 16px;
@default-font-size: 14px;
@medium-font-size: 13px;
@small-font-size: 12px;
@xsmall-font-size: 10px;

/*
 * Wizard navigation colors
 */
@nav-wizard-step-background-color: #eee;
@nav-wizard-active-step-text-color: #fff;
@nav-wizard-active-step-background-color: #428bca;
@nav-wizard-active-tab-text-color: #fff;
@nav-wizard-active-tab-background-color: #428bca;
@nav-wizard-disabled-step-text-color: #777;
@nav-wizard-hover-background-color: #d5d5d5;
@nav-wizard-not-last-step-border-color: #eee;
@nav-wizard-not-first-step-border-color: #fff;

/*
 * Space definitions - padding, margin, etc.
 */
@space-indent: 15px;
@space-light: 15px;
@space-medium: 50px;
@space-heavy: 100px;

/*
 * Top navigation document setup
 */
@sticky-site-navigation-height: 60px;
@sticky-site-navigation-padding: 10px;

/*
 * Text Area sizing
 */
@text-area-default-height: 25em;
@text-area-default-width: 27em;
