@import 'variables';

tags-input {
  & .tags {
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    & .input {
      font: inherit;
    }
  }

  .tags {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &.focused {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    .tag-item {
      background: #eeeeee;
      border: 1px solid #cccccc;
      border-radius: 4px;
      color: #555555;

      &.selected {
        background: #d9534f;
        border: 1px solid #d43f3a;
        border-radius: 4px;
        color: #ffffff;
      }

      button {
        background: transparent;
        color: #000000;
        opacity: 0.4;
      }
    }
  }

  .autocomplete {
    border-radius: 4px;

    .suggestion-item {
      &.selected {
        background-color: #f5f5f5;
        color: #262626;
      }

      em {
        background-color: #ffff99;
        border-radius: 4px;
      }

      &.selected {
        em {
          background-color: #ffff99;
          border: 1px solid #c5c5c5;
          border-radius: 4px;
          color: #262626;
        }
      }
    }
  }
}
