@import (css) url('//fonts.googleapis.com/css?family=Droid+Sans:400,700');
@import (css) url('//fonts.googleapis.com/css?family=Open+Sans:400,600');

@import 'variables';
@import 'bootstrap-overrides';
@import 'st-table-overrides';

html, body {
  color: @default-font-color;
  font-family: @default-font-family;
}

h1, h2, h3, h4, h5, h6 {
  color: @default-font-color;
  font-family: @header-font-family;
}

table {
  word-break: break-word;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;

  &.half {
    width: 50%;
  }
}

.compact-row {
  padding-bottom: 0;
}

.dataset {
  &.actual {
    background-color: @dataset-actuals-background-color;
  }

  &.actualsPYoY {
    background-color: @dataset-actuals-yoy-py-background-color;
  }

  &.actualsPPYoY {
    background-color: @dataset-actuals-yoy-ppy-background-color;
  }

  &.actual-comparison {
    background-color: @dataset-actual-comparison-first-background-color;

    &.second {
       background-color: @dataset-actual-comparison-second-background-color;
    }
  }

  &.comparison {
    background-color: @dataset-comparison-first-background-color;

    &.second {
       background-color: @dataset-comparison-second-background-color;
    }
  }

  &.cumulative {
    background-color: @dataset-cumulative-background-color;
  }

  &.cumulative-end-of-week {
    background-color: @dataset-cumulative-end-of-week-background-color;
  }

  &.default {
    background-color: @dataset-default-background-color;
  }

  &.delta {
    background-color: @dataset-delta-background-color;
  }

  &.edit {
    background-color: @dataset-edits-background-color;
  }

  &.missPercent {
    background-color: @dataset-percent-background-color;
  }

  &.percent {
    background-color: @dataset-percent-background-color;
  }

  &.primary {
    background-color: @dataset-primary-background-color;
  }
}

.environment {
  &.beta {
    background: @color-env-beta;
    color: @contrast-font-color;
  }

  &.gamma {
    background: @color-env-gamma;
    color: @contrast-font-color;
  }

  &.local {
    background: @color-env-local;
    color: @contrast-font-color;
  }

  &.prod {
    background: @color-env-prod;
    color: @contrast-font-color;
  }
}

.inline-block {
  display: inline-block;
}

.fa[ng-click], .fa-interactive {
  font-size: @large-font-size;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 1;
    text-decoration: none;
  }
}

.font-bold {
  font-weight: bold !important; // lesshint importantRule: false
}

.full-width {
  width: 100%;
}

.light-inset {
  padding: @space-light;
}

.light-inset-left {
  padding-left: @space-light;
}

.no-height {
  height: 0;
}

.vertical-space-light {
  padding-bottom: @space-light;
  padding-top: @space-light;
}

.singularity {
  margin-bottom: 90px;
  margin-top: 90px;
}

.text-area {
  &.normal {
    height: @text-area-default-height;
    width: @text-area-default-width;
  }
}

.text-jumbo {
  font-size: @jumbo-font-size;
}

.text-large {
  font-size: @large-font-size;
}

.text-medium {
  font-size: @medium-font-size;
}

.text-small {
  font-size: @xsmall-font-size;
}

.uuid {
  cursor: pointer;
}

@import 'alert';
@import 'allocation-tree-editor';
@import 'announcements';
@import 'application-loading';
@import 'banner';
@import 'binary-switch';
@import 'chart';
@import 'clock';
@import 'collapse-switch';
@import 'confirmation-popover';
@import 'constraints-uploader';
@import 'daily-flash-metrics';
@import 'date-range-selector';
@import 'developer-tools';
@import 'file-selector';
@import 'grain-group-by-selector';
@import 'grid';
@import 'group-list';
@import 'hamburger-dropdown';
@import 'help';
@import 'historical-date-selector';
@import 'home';
@import 'item-selector';
@import 'metrics-dashboard';
@import 'nav-wizard';
@import 'overlay';
@import 'panel';
@import 'plan-audit';
@import 'plan-orchestration';
@import 'plan-selector';
@import 'preferences';
@import 'preferences-modal';
@import 'modal-instance';
@import 'ng-tags';
@import 'rom-scenario-manager';
@import 'scope-icon';
@import 'scroll-to';
@import 'shortcuts';
@import 'single-item-selector';
@import 'site-footer';
@import 'site-header';
@import 'smart-table';
@import 'standard-modal';
@import 'term-table';
@import 'tile';
@import 'tree-item-selector';
@import 'user-permissions';
@import 'user-profile';
@import 'view-modal';
@import 'view-selector';
@import 'viewer-settings';
@import 'workflows';
