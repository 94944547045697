@import 'variables';

.daily-flash-metrics {
  .fixed-metric {
    width: 450px;
  }

  .fixed-value {
    width: 150px;
  }

  .fixed-status {
    width: 250px;
  }

  .flash-metrics-table {
    margin-top: -15px;
  }

  .invalid-value {
    color: @color-text-danger;
  }

  .validated-value {
    color: @color-text-warning;
  }
}
