@import 'variables';

.preferences-modal {
  .selections {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: @space-indent;

    .selection {
      border: @color-control-soft-border solid 1px;
      border-radius: 5px;
      flex-grow: 0;
      margin: 2px;
      min-width: 60px;
      padding: 0 10px;
    }
  }
}
