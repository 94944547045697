@import 'variables';

th {
  &[st-sort] {
    cursor: pointer;
  }

  &.st-sort-ascent:before {
    // Black Large Up Arrow
    content: '\25B2';
  }

  &.st-sort-descent:before {
    // Black Large Down Arrow
    content: '\25BC';
  }
}
