@import 'variables';

.home {
  .tiles-dashboard-container {
    margin-right: 10px;
    text-align: right;
  }

  .vertical-spacing {
    padding-bottom: @space-light;
    padding-top: @space-light;
  }
}
