/**
 * Defines hard dimension functions that force specific dimensions across all properties.
 */

.hard-height(@height) {
  max-height: @height;
  min-height: @height;
  width: @height;
}

.hard-width(@width) {
  max-width: @width;
  min-width: @width;
  width: @width;
}
