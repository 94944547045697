@import 'variables';
@import 'mixins/hard-dimensions';

.item-selector {
  .row.all-items-filtered-row {
    font-weight: bold;
    text-align: center;
  }

  .items {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: @space-indent;

    .item {
      border: @color-control-soft-border solid 1px;
      border-radius: 5px;
      flex-grow: 0;
      margin: 2px;
      min-width: 60px;
      padding: 0 10px;

      label {
        font-weight: normal;
      }

      .only {
        display: none;
        font-size: @xsmall-font-size;
        margin-top: -14px;
        text-align: right;

        span {
          cursor: pointer;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .item:hover .only {
      display: block;
    }
  }

  .toolbar {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    // Exerting tight control over the layout of this component.
    .search-and-save-preferences {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding-top: 10px;

      .hard-width(380px);

      search-input {
        margin-right: 10px;

        .hard-width(265px);
      }

      save-preferences {
        margin-right: 5px;
      }
    }
  }

  .multi-select-item {
    margin: 2px;
    min-width: 50px;

    label {
      display: inline-block;
    }
  }

  &.compact {
    .toolbar {
      justify-content: flex-start;

      .search-and-save-preferences {
        display: none;
      }
    }

    .multi-select-item > label {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
