@import 'variables';

.allocation-tree-editor {
  .multi-select-item {
    margin-right: 7px;
  }

  .secondary-scope-suffix {
    float: right;
    text-align: left;
    width: 75%;
  }

  .title {
    font-family: @header-font-family;
    font-size: @large-font-size;
    line-height: 36px;
  }
}
