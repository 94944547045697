@import 'variables';

.preferences {
  button {
    &.no-preference {
      background-color: @color-page-background;
    }

    &.preference {
      background-color: @color-preferences-background;
    }
  }
}
