@import 'variables';

scope-icon {
  .scope-icon {
    font-size: @large-font-size;
    vertical-align: middle;
  }

  .scope-name {
    padding-left: 10px;
  }
}

.modal-title {
  .scope-icon {
    font-size: 1.5em;
    line-height: 28px;
  }
}

.scope-selector {
  .scope-icon {
    font-size: 2.5em;
    line-height: 29px;
  }
}
