@import 'variables';

.alert-bar {
  .alert {
    > .close {
      // Moving the close button up in the z-order so it is infront of the countdown animation
      z-index: 2;
    }
  }

  .alert:last-child {
    margin-bottom: 0;
  }
}

.alert-container {
  .alert-countdown {
    color: @subtle-font-color;
    float: right;
    opacity: 0.2;
    position: relative;
    right: -45px;
    top: -6px;
  }

  // Target FireFox specifically to correct for layout differences from Chrome
  @-moz-document url-prefix() {
    .alert-countdown {
      top: -5px;
    }
  }

  > .alert-details {
    padding-top: @space-light;
    word-wrap: break-word;
  }

  > .badge {
    float: right;
    opacity: 0.2;
  }

  .alert-countdown + .badge {
    position: relative;
    right: -37px;
  }
}
