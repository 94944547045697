@import 'variables';

.panel {
  > .panel-heading {
    font-weight: bold;
    padding: 10px;
  }

  &&-disabled {
    border-color: #e3e3e3;

    & > .panel-heading {
      background-color: #f5f5f5;
      border-color: #e3e3e3;
      color: #000000;
    }
  }

  &&-horizontal {
    display: table;
    width: 100%;

    & > .panel-body, & > .panel-footer, & > .panel-heading {
      border-radius: @border-radius-default;
      display: table-cell;
    }

    & > .panel-footer, & > .panel-heading {
      border: 0;
      vertical-align: middle;
      width: 30%;
    }

    & > .panel-footer {
      border-bottom-right-radius: @border-radius-default;
      border-left: 1px solid #dddddd;
      border-top-left-radius: 0;
    }

    & > .panel-heading {
      border-radius: @border-radius-default 0 0 @border-radius-default;
      border-right: @border-width-default solid #dddddd;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
