@import 'variables';

.with-help {
  position: relative;
}

.help-overlay {
  background-color: transparent;
  border-radius: 50%;
  height: @default-font-size;
  position: absolute;
  right: -5px;
  top: -9px;
  width: @default-font-size;
}

.search-help-overlay {
  background-color: transparent;
  border-radius: 50%;
  height: @default-font-size;
  position: absolute;
  right: 257px;
  top: -9px;
  width: @default-font-size;
}

.help-popover {
  font-size: @small-font-size;
}

.help-for-binary-switch {
  vertical-align: 2px;
}

.help-for-region {
  vertical-align: -0.5px;
}
