@import 'variables';

body {
  /*
   * Bootstrap specified top padding for body element to deal with fixed top navbar.
   * Documented at: http://getbootstrap.com/components/#navbar-fixed-top
   */
  padding-top: @sticky-site-navigation-height + @sticky-site-navigation-padding;
}

/*
 * Angular-UI Bootstrap specified styling to allow removal of empty href attributes from directive templates.
 * Documented at: https://angular-ui.github.io/bootstrap/
 *
 * Made anchors always have a cursor on them because there is not a logical case where an anchor should not have a cursor.
 */
a {
  cursor: pointer;
}

/*
 * As a standard we wrap a checkbox or radio input in the label element. e.g.,
 *
 * <label class="checkbox">
 *   <input type="checkbox">
 * </label>
 *
 * Bootstrap defines a -20px margin on the checkbox and radio inputs.
 * The padding here keeps the checkbox inside the label's boundaries for correct rendering in its container.
 */
label.checkbox, label.radio {
  padding-left: 20px;
}

/*
 * Bootstrap Popover styling
 */
[popover-trigger="'click'"] {
  cursor: pointer;
}

.popover {
  font-family: inherit;
  max-width: 400px;
}

/*
 * Bootstrap Row styling
 */
.row {
  padding-bottom: @space-light;
}

/*
 * Bootstrap Nav Tabs styling
 */
.nav.nav-tabs {
  > .nav-item {
    font-size: @large-font-size;

    &.active > a {
      background-color: @nav-wizard-active-tab-background-color;
      color: @nav-wizard-active-tab-text-color;
    }
  }
}

/*
 * Bootstrap Nav Brand img styling
 */
.navbar-header > .navbar-brand > img {
  height: 24px;
  width: 24px;
}

/*
 * Angular-UI Bootstrap Panel styling for clickable heading
 */
.panel-heading[ng-click] {
  cursor: pointer;
}

.tab-content {
  padding-top: @space-light;
}

.text-muted {
  color: @subtle-font-color;
}
