@import 'variables';

footer {
  background-color: @color-page-trim-background;
  border-color: @color-page-trim-border;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 1px 0;
  font-size: @footer-font-size;
  margin-top: @space-light;
  padding-top: @space-light;
}
