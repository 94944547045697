.modal-xl {
  width: 1102px;
}

.modal-instance-header {
  padding-bottom: 0;

  .modal-instance-flag-icon {
    float: left;
    margin-right: 5px;
  }

  .modal-instance-actions-bar {
    float: right;

    .modal-instance-modification-icon {
      position: relative;
      top: 5px;
    }
  }
}

.modal-instance-body {
  .modal-pill-selector-group {
    display: flex;

    > .modal-pill-selector {
      flex-grow: 1;
      margin-left: 10px;
    }

    > .modal-pill-selector-toggle {
      align-self: start;
      display: flex;
      flex-wrap: nowrap;
      margin-top: 5px;
    }
  }
}
