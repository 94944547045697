@import 'variables';

binary-switch {
  label {
    font-size: @default-font-size !important; // lesshint-disable-line importantRule
    vertical-align: 2px;

    &.left {
      padding-right: 7px;
    }

    &.right {
      padding-left: 7px;
    }
  }
}

.binary-switch {
  background: @color-control-default-background;
  border: 1px solid @color-control-soft-border;
  border-radius: 20px;
  box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
  top: 4px;
  transition: 0.3s ease-out all;
  width: 40px;

  small {
    background: @color-control-default-background;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.3s ease-out all;
    width: 20px;
  }

  &.checked {
    background: rgb(100, 189, 99);
    border-color: rgb(100, 189, 99);

    small {
      left: 22px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.50;
  }
}

legend span {
  binary-switch {
    label {
      margin-bottom: 0;
      position: relative;
      top: 4px;
    }
  }

  .binary-switch {
    top: 2px;
  }
}
