@import 'variables';
@import './mixins/dropdown-list';
@import './mixins/selector-button';

.view-selector {
  display: inline-block;

  #view-selector-button { // lesshint-disable-line idSelector
    .selector-button();
  }
}

.view-selector-dropdown-menu {
  max-width: 300px;

  .save-view-section {
    padding: 0 14px;
    position: relative;

    .alert {
      font-size: @small-font-size;
      margin-bottom: 0;
      margin-top: 8px;
      padding: 5px;
    }
  }

  .dropdown-list();
}
