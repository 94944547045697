@import 'variables';

.banner {
  @box-size: 175px;
  @banner-width: 175px;
  @banner-height: 50px;
  @origin: (@banner-width - @banner-height) / (2 * sqrt(2));
  @banner-horizontal-position: @origin - (@banner-width / 2);
  @banner-vertical-position: @origin - (@banner-height / 2);
  @rotateDegrees: 45deg;

  display: none;
  height: @box-size;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  width: @box-size;
  z-index: 1000;

  &.banner-enabled {
    display: block;
  }

  .banner-text {
    cursor: default;
    display: block;
    font-family: @header-font-family;
    font-size: @large-font-size;
    font-weight: bold;
    height: @banner-height;
    line-height: @banner-height;
    position: relative;
    text-align: center;
    width: @banner-width;
  }

  &.top-left {
    left: 0;
    top: @sticky-site-navigation-height;

    .banner-text {
      left: @banner-horizontal-position;
      top: @banner-vertical-position;
      transform: rotate(-@rotateDegrees);
    }
  }

  &.top-right {
    right: 0;
    top: @sticky-site-navigation-height;

    .banner-text {
      right: @banner-horizontal-position;
      top: @banner-vertical-position;
      transform: rotate(@rotateDegrees);
    }
  }
}
