.grain-group-by-selector {
  .panel-group {
    padding: @space-light;

    .panel {
      [sv-element] {
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        cursor: grab;
        margin-bottom: 20px;
        min-height: 20px;
        padding: 9px;
      }

      &.panel-success {
        [sv-element] {
          background-color: #dff0d8;
          border-color: #d6e9c6;
          color: #3c763d;
        }
      }

      // sv-helper is used for the element currently being dragged
      .sv-helper {
        cursor: grabbing;
      }
    }

    .save-manage-preferences {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;

      save-preferences {
        margin-right: 5px;
      }
    }
  }
}
