@import 'variables';

.nav-wizard {
  > li {
    float: left;

    > a {
      background-color: @nav-wizard-step-background-color;
      position: relative;
    }

    &.actions {
      padding: 3px 15px 3px 30px;
    }

    &:not(:first-child) {
      > a {
        padding-left: 34px;

        &:before {
          border-bottom: 20px inset transparent;
          border-left: 20px solid @nav-wizard-not-first-step-border-color;
          border-top: 20px inset transparent;
          content: '';
          height: 0;
          left: 0;
          position: absolute;
          top: 0;
          width: 0;
        }
      }
    }

    &:not(:nth-last-child(2)) {
      > a {
        margin-right: 6px;

        &:after {
          border-bottom: 20px inset transparent;
          border-left: 20px solid @nav-wizard-not-last-step-border-color;
          border-top: 20px inset transparent;
          content: '';
          height: 0;
          position: absolute;
          right: -20px;
          top: 0;
          width: 0;
          z-index: 2;
        }
      }
    }

    &:first-child {
      > a {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    &:nth-last-child(2) {
      > a {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    &:hover {
      > a {
        background-color: @nav-wizard-hover-background-color;

        &:before {
          border-right-color: @nav-wizard-hover-background-color;
        }

        &:after {
          border-left-color: @nav-wizard-hover-background-color;
        }
      }
    }

    &.active {
      > a {
        background-color: @nav-wizard-active-step-background-color;
        color: @nav-wizard-active-step-text-color;

        &:hover {
          background-color: @nav-wizard-active-step-background-color;
          color: @nav-wizard-active-step-text-color;
        }

        &:focus {
          background-color: @nav-wizard-active-step-background-color;
          color: @nav-wizard-active-step-text-color;
        }

        &:after {
          border-left-color: @nav-wizard-active-step-background-color;
        }
      }
    }

    &.disabled {
      > a {
        color: @nav-wizard-disabled-step-text-color;

        &:hover {
          background-color: @nav-wizard-step-background-color;
          color: @nav-wizard-disabled-step-text-color;
          cursor: default;
          text-decoration: none;
        }

        &:focus {
          background-color: @nav-wizard-step-background-color;
          color: @nav-wizard-disabled-step-text-color;
          cursor: default;
          text-decoration: none;
        }

        &:before {
          border-right-color: @nav-wizard-step-background-color;
        }

        &:after {
          border-left-color: @nav-wizard-step-background-color;
        }
      }
    }
  }
}
