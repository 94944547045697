@import 'variables';
@import './mixins/overflow-ellipsis';

.announcements {
  legend {
    font-size: @default-font-size !important; // lesshint-disable-line importantRule
    font-weight: bold !important; // lesshint-disable-line importantRule
    padding: 0 10px;

    > span, > i {
      background: @color-page-background;
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      top: 10px;
    }
  }

  .modal-preview {
    min-height: 55px;
  }

  .truncate-fixed-message {
    .overflow-ellipsis();
    width: 500px;
  }

  .truncate-fixed-scopes {
    .overflow-ellipsis();
    width: 200px;
  }

  .fixed-actions {
    width: 150px;
  }
}
