@import 'variables';

.developer-tools {
  .inputs-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0;

    .container-label {
      padding-top: 7px;
    }

    .container-input {
      padding: 0 25px 0 10px;
    }
  }

  .vertical-padding {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .vertical-padding-compact {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  div.row-compact {
    padding-bottom: 5px;
  }

  div.right-border {
    border-right: 1px solid @color-control-border;
  }

  div.top-padding {
    padding-top: 7px;
  }

  table.bottom-margin-compact {
    margin-bottom: 5px;
  }
}
