@import 'variables';

header {
  .non-anchor-menu-item {
    padding: 3px 20px;

    .shortcut-icon {
      font-size: @large-font-size;
    }
  }

  .clock-container {
    padding-top: @space-light;
  }
}

// The dropdown menu element is attached to the document body and not a child of the site header
.scope-selector-dropdown-menu {
  li {
    padding-top: 3px;

    &.divider {
      margin-top: 0;
      padding-bottom: 0;
    }

    &.selected a {
      font-weight: 700;
    }
  }
}

.tenant-selector {
  font-size: @large-font-size;
  font-weight: bold;
  text-align: right;

  > a {
    padding-bottom: 20px !important; // lesshint-disable-line importantRule
    padding-right: 5px !important; // lesshint-disable-line importantRule
    padding-top: 20px !important; // lesshint-disable-line importantRule
  }
}

.tenant-selector-dropdown-menu {
  li {
    padding-top: 3px;

    a {
      font-weight: 500;
    }

    &.selected a {
      font-weight: 700;
    }
  }
}
