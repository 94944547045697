@import 'variables';

.viewer-settings {
  .panel-body > [class*='col-']:not(:last-child):after {
    background: @color-page-trim-border;
    bottom: 0;
    content: '';
    display: block;
    min-height: 70px;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }

  .viewer-header {
    font-weight: 400;

    .viewer-header-steps {
      float: left;

      .nav-pills {
        display: flex;
        justify-content: center;

        > li {
          > a {
             background-color: @nav-wizard-step-background-color;
             border-radius: 0;
          }

          &.active {
            > a {
              background-color: @nav-wizard-active-step-background-color;
              color: @nav-wizard-active-step-text-color;
            }
          }
        }
      }
    }

    .viewer-header-widgets {
      float: right;

      .viewer-header-widget {
        border-right: 1px solid @color-control-border;
        display: inline-block;
        line-height: 42px;
        min-height: 42px;
        padding: 0 1em;

        .viewer-header-label {
          font-size: @default-font-size;
          vertical-align: top;
        }
      }
    }
  }

  .viewer-section {
    legend {
      font-size: @default-font-size !important; // lesshint-disable-line importantRule
      font-weight: bold !important; // lesshint-disable-line importantRule
      padding: 0 10px;
    }

    legend span {
      background: @color-page-background;
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      top: 10px;
    }

    p {
      background: @color-page-background;
      font-size: @default-font-size !important; // lesshint-disable-line importantRule
      font-weight: bold !important; // lesshint-disable-line importantRule
      padding: 0 10px;
    }
  }
}
