@import 'variables';

.sheet-stage {
  border-radius: 90px;
  color: white;
  font-size: 60px;
  margin-bottom: 10px;
  padding: 0.5em;
}

.sheet-stage-default {
  background-color: #eaeaea;
  box-shadow: 0 0 0 2pt #dcdcdc;
}

.sheet-stage-failed {
  background-color: #d6a1a1;
  box-shadow: 0 0 0 2pt #b38282;
}

.sheet-stage-success {
  background-color: #a1d6a1;
  box-shadow: 0 0 0 2pt #83b383;
}
