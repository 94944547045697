@import 'variables';
@import './mixins/dropdown-list';
@import './mixins/selector-button';

.historical-date-selector {
  #date-selector-button { // lesshint-disable-line idSelector
    .selector-button();
  }

  .dropdown-menu {
    min-width: 100%;
  }

  .dropdown-list();
}
