@import 'variables';

.collapse-switch {
  cursor: pointer;
  margin-left: @space-light;
}

.viewer-header {
  .collapse-switch {
    line-height: 42px;
  }
}
