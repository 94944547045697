@import 'variables';

.tree-item-selector {
  margin-left: @space-indent;

  label {
    display: inline-block;
  }

  .tree-group {
    > .node {
      .collapse-switch {
        display: none;
      }

      &:hover {
        .collapse-switch {
          display: inline-block;
        }
      }

      > label {
        margin: 5px 0;
      }
    }
  }

  .tree-node {
    display: inline-block;

    > .node {
      border: @color-control-soft-border solid 1px;
      border-radius: 5px;
      margin: 2px;
      min-width: 80px;
      padding: 0 10px;
      text-align: center;

      > label {
        font-weight: normal;
      }
    }
  }
}
