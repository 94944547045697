@import 'variables';

.term-table {
  tbody > tr {
    td {
      border: 1px solid @color-control-border;
      border-left: 0 none;
    }

    th {
      border: 1px solid @color-control-border;
      border-right: 0 none;
      white-space: nowrap;
    }
  }
}
