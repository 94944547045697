@import 'variables';

.standard-modal {
  .header-layout {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .modal-section {
    legend {
      font-size: @default-font-size !important; // lesshint-disable-line importantRule
      font-weight: bold !important; // lesshint-disable-line importantRule
      padding: 0 10px;

      span, i {
        background: @color-page-background;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        top: 10px;
      }
    }
  }
}
