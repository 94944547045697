@import 'variables';

.hamburger-dropdown {
  > a {
    color: @color-panel-heading-icon;

    &:hover {
      color: @color-panel-heading-icon;
    }

    > i {
      position: relative;
      top: 4px;
    };
  };
};

.hamburger-menu {
  li {
    align-items: center;
    display: flex;
  };
}
