@import 'variables';

.user-profile {
  .alias-actions {
    padding-left: 0;
    padding-right: 0;

    &.edit-action {
      padding-top: 0;
    }

    &.lookup-action {
      padding-top: 3px;
    }
  }

  .alias-container {
    height: 26px;
    padding-left: 0;
    padding-right: 0;
  }

  .alias-row {
    padding-bottom: 0;
  }

  .tenant-selector-container {
    margin-bottom: 15px;
    width: 575px;
  }
}
