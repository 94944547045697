@import 'variables';

.smart-table-loading {
  background-color: @color-control-default-background;
  text-align: center;
}

.smart-table-no-data {
  background-color: @color-control-default-background;
  text-align: center;
}

.smart-table-truncate-fixed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
