@import 'variables';

.metrics-dashboard {
  .row-top-padding {
    padding-bottom: 3px;
    padding-top: 20px;
  }

  .view-configuration-container {
    padding-right: 10px;
    padding-top: 5px;
    text-align: right;
  }
}
