@import './mixins/dropdown-list';
@import './mixins/selector-button';

.single-item-selector {
  button {
    .selector-button();
  }

  ul {
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dropdown-list();
}
