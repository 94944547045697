@import 'variables';

.view-modal {
  legend {
    font-size: @default-font-size !important; // lesshint-disable-line importantRule
    font-weight: bold !important; // lesshint-disable-line importantRule
    padding: 0 10px;
  }

  legend span {
    background: @color-page-background;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: 10px;
  }

  .header-layout {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
