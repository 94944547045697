@import 'variables';
@import './mixins/hard-dimensions';
@import './mixins/overflow-ellipsis';

.grid-container {
  .grid-caption {
    .grid-title {
      font-family: @header-font-family;
      font-size: @large-font-size;
      font-weight: normal;
      line-height: 36px;

      .grid-actions {
        display: inline-block;
        font-size: @default-font-size;
        margin-left: @space-light;

        .grid-actions-container {
          display: inline-block;

          > div {
            display: inline-block;
          }
        }
      }
    }

    .grid-status {
      border-top: 1px solid @color-control-border;
      margin-bottom: @space-light;
      padding-top: @space-light;
    }
  }

  table.grid {
    border: 1px solid @color-control-border;
    font-size: @medium-font-size;
    table-layout: fixed;

    tr {
      // The height of a row needs to be rigidly defined in order to permit the grid-virtual-scroll.directive.js to pre-compute spacer dimensions
      // on initialization. This value and the DEFAULT_FIXED_ROW_HEIGHT_IN_PIXELS constant in the directive should be identical.
      .hard-height(29px);
    }

    td {
      font-family: @data-grid-font-family;

      input[type='text'] {
        border: 0;
        padding: 5px;
        text-align: right;
        width: 100%;

        &:valid {
          background-color: @dataset-edits-background-color;
        }

        &[value=''], &:not([value]) {
          background-color: inherit;
        }

        &:invalid {
          background-color: @color-control-invalid-background;
        }

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        // for network-planner grid, no delta means not-edited
        &.not-edited {
          background-color: inherit;
        }
      }
    }

    th, td {
      border-right: 1px solid @color-control-border;

      .overflow-ellipsis();

      &.highlight {
        background-color: @color-grid-group-highlight;
        font-weight: bold;
      }

      &.historical-divider {
        border-right: 5px solid @color-control-border;
      }

      &.metric-family-divider {
        border-top: 15px solid @color-control-border;
      }

      &.metric-category-divider {
        border-top: 5px solid @color-control-border;
      }

      &.text-strong {
        font-weight: bold;
      }

      &.text-alarm {
        color: @alarm-font-color;
        font-weight: bold;
      }

      &.text-preview {
        color: @subtle-font-color;
      }

      &.today {
        background-color: @color-today-background;
      }

      &:last-child {
        border-right: 0;
      }

      &.granularity {
        background-color: @color-page-background;
      }

      &.aggregate-cell {
        background: @header-aggregate-background-color;
      }

      &.no-padding {
        padding: 0 !important; // lesshint-disable-line importantRule
      }
    }

    thead > tr > th {
      background-color: @color-page-background;
      border-bottom: 1px solid @color-control-border;
    }

    thead > tr > td {
      background-color: @color-page-background;
      border-bottom: 1px solid @color-control-border;
      font-weight: bold;
    }

    tbody > tr > th {
      font-weight: normal;
    }

    tbody > tr > td, tfoot > tr > td {
      border: 0 none;
      border-top: 1px dotted @color-control-border;
    }

    tfoot {
      border-top: 5px double @color-control-border;
    }

    .editable {
      cursor: pointer;
    }

    .non-editable {
      background-color: @color-grid-non-editable-background;
      color: @color-grid-non-editable;
    }

    .small-cell-width {
      .hard-width(100px);
    }

    .medium-small-cell-width {
      .hard-width(125px);
    }

    .medium-cell-width {
      .hard-width(150px);
    }

    .medium-large-cell-width {
      .hard-width(190px);
    }

    .large-cell-width {
      .hard-width(230px);
    }

    .xlarge-cell-width {
      .hard-width(350px);
    }
  }

  .chart-input-container {
    div.chart-input, div.chart-input-selection {
      margin-bottom: 10px;
    }
  }

  .disabled-chart-icon {
    opacity: 0.5;
  }
}
